<template>
  <div class="az-content az-content-profile">
    <div class="container mn-ht-100p">
      <div class="az-content-left az-content-left-profile">

        <div class="az-profile-overview">
          <div class="az-img-user">
            <div :style="org_image_background" id="profile-upload">

            </div>
          </div>
          <div class="d-flex justify-content-between mg-b-20">
            <div>
              <h5 class="az-profile-name" v-if="currentUser">{{accountDetails.name}}</h5>
              <p class="az-profile-name-text text-center">{{accountDetails.email}}</p>
            </div>
          </div>

          <div class="az-profile-bio">
            {{accountDetails.description}}
          </div>

          <hr class="mg-y-100">

        </div><!-- az-profile-overview -->

      </div><!-- az-content-left -->
      <div class="az-content-body az-content-body-profile">
        <div class="az-tab-content">
          <div :class="{'d-none': activeTab !== 0}" class="az-profile-body" id="account">
            <div class="row mg-b-20">
              <div class="col-md-12 col-xl-12">
                <div class="az-profile-view-chart">
                  <div class="az-profile-view-info col-md-12">
                    <div class="d-flex align-items-baseline">
                      <h6>Organization Settings</h6>
                      <div class="mg-l-auto">
                        <router-link class="router-link-active" href="/myorg/" :to="{name: 'myorg' }">
                          <i class="fa fa-arrow-left"></i>
                          Back
                        </router-link>
                      </div>
                    </div>
                    <p>Please keep your profile information always current in order to help you when you contact us.</p>
                    <div class="mg-t-35">
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-6 col-lg-6">
                          <label class="form-control-label">Name: </label>
                          <input class="form-control rounded" data-vv-as="First Name" id="name" name="name" placeholder="Enter Organization Name" type="text" v-model="accountDetails.name">
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <label class="form-control-label">Website: </label>
                          <input class="form-control rounded" data-vv-as="Last Name" id="web" name="web" placeholder="Enter Website" type="text" v-model="accountDetails.web">
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-6 col-lg-6">
                          <label class="form-control-label">Contact No: </label>
                          <input class="form-control rounded" data-vv-as="First Name" id="phone" name="phone" placeholder="Enter Contact Number" type="text" v-model="accountDetails.phone">
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <label class="form-control-label">Email: </label>
                          <input class="form-control rounded" data-vv-as="Email" id="email" name="email" placeholder="Enter Email" type="text" v-model="accountDetails.email">
                        </div>
                      </div>
                      <div class="row row-xs align-items-center">
                        <div class="col-md-6 col-lg-6">
                          <label class="form-control-label">Address: <span class="tx-danger">*</span></label>
                          <input class="form-control rounded" data-vv-as="Organization address line 1" id="org_line1" name="org_line1" placeholder="Enter Address line 1" type="text" v-model="accountDetails.line1">
                          <!---->
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <input class="form-control rounded" data-vv-as="Organization address line 1" id="org_line2" name="org_line2" placeholder="Enter Address line 2" style="margin-top: 1.8rem;" type="text" v-model="accountDetails.line2">
                          <!---->
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-t-10">
                        <div class="col-md-6 col-lg-6">
                          <input class="form-control rounded" data-vv-as="Organization address city" id="org_city" name="org_city" placeholder="Enter City" type="text" v-model="accountDetails.city">
                          <!---->
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <input class="form-control rounded" data-vv-as="Organization address state" id="org_state" name="org_state" placeholder="Enter State" type="text" v-model="accountDetails.state">
                          <!---->
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-t-10">
                        <div class="col-md-6 col-lg-6">
                          <input class="form-control rounded" data-vv-as="Organization address line 1" id="org_zip" name="org_zip" placeholder="Enter Postal Code" type="text" v-model="accountDetails.zip">
                          <!---->
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <select class="form-control select2 select2-hidden-accessible rounded" data-select2-id="1" data-vv-as="Organization address country" name="org_country" tabindex="-1" v-model="accountDetails.country">
                            <option label="Choose Country" value="null"></option>
                            <option v-bind:key="index" v-for="(member, index) in countries">{{member.name}}
                            </option>
                          </select>
                          <!---->
                        </div>
                      </div>
                      <div class="row row-sm form-group mg-t-10">
                        <div class="col-lg-3 offset-lg-10">
                          <button @click="update" class="btn btn-orange rounded w-80 font-weight-bold mg-l-35" title="Add Catalog"><i class="fas fa-save"></i>&nbsp;&nbsp;Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mg-b-20"></div>

          </div><!-- az-profile-body -->
        </div>
      </div><!-- az-content-body -->
    </div><!-- container -->
  </div><!-- az-content -->
</template>

<script>
import { mapGetters } from "vuex";
import countries from "../../../assets/data/countries";
import EventBus from "@/event-bus";

export default {
  name: "Edit",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      activeTab: 0,
      countries: countries,
      accountDetails: {
        account_id: null,
        name: null,
        email: null,
        web: null,
        phone: null,
        address_id: null,
        line1: null,
        line2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      org_image_background: localStorage.getItem("org_image") ? 'background-image:url(\'' + localStorage.getItem("org_image") + '\')' : ''
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let reply = await this.$accounts.get(`/accounts/org`);
      this.accountDetails = reply.data;
      this.accountDetails.address_id = this.accountDetails.address.address;
      this.accountDetails.line1 = this.accountDetails.address.line1;
      this.accountDetails.line2 = this.accountDetails.address.line2;
      this.accountDetails.city = this.accountDetails.address.city;
      this.accountDetails.state = this.accountDetails.address.state;
      this.accountDetails.zip = this.accountDetails.address.zip;
      this.accountDetails.country = this.accountDetails.address.country;
    },
    async update() {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.post(`/accounts/org`, this.accountDetails);
        this.$toastr.s("Organization Settings Successfully Updated!", 'Success');
      } catch (e) {
        console.log(e);
      } finally {
        EventBus.$emit('closeLoader');
        await this.$router.push('/myorg');
      }
    }
  }
}
</script>

<style scoped>
</style>